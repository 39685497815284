import { useOfQuery } from 'domains/of/queries'
import { useSyncMethodsMutation } from 'domains/sasMethods/mutations'
import { useAvailabilityQuery } from 'domains/sasMethods/queries'
import {
  Availability,
  SASStatus,
  SyncMethodsLists,
} from 'domains/sasMethods/types'
import { isEmpty, isEqual, uniq } from 'lodash'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { REFETCH_INTERVAL } from '../enums'
import { SyncMethodsContextType } from './types'

const SyncMethodsContext = createContext<SyncMethodsContextType>(null)

const DEFAULT_SYNC_METHODS = { globalSyncMethods: [], singleSyncMethods: [] }

function SyncMethodsProvider({ id, children }) {
  const [autoRefetch, setAutoRefetch] = useState(true)

  const {
    data: trainingOrganization,
    error,
    isLoading,
  } = useOfQuery(
    { id },
    {
      refetchInterval: autoRefetch ? REFETCH_INTERVAL.SHORT : false,
      enabled: !isEmpty(id),
    },
  )

  const { data: sasStatus, refetch: refetchStatus } = useAvailabilityQuery(
    trainingOrganization?.url,
    {},
    {
      refetchInterval: autoRefetch ? REFETCH_INTERVAL.SHORT : false,
      enabled: !isEmpty(trainingOrganization),
      onSuccess: (result: SASStatus) => {
        setAutoRefetch(result?.availability === Availability.Busy)
      },
    },
  )
  const getSyncMethods = useSyncMethodsMutation(trainingOrganization?.url, {})
  const [relatedSyncMethods, setRelatedSyncMethods] =
    useState<SyncMethodsLists>(DEFAULT_SYNC_METHODS)

  useEffect(() => {
    if (!isEmpty(trainingOrganization) && isEmpty(error)) {
      refetchStatus()
      const fetchSyncMethods = async () => {
        const apiMethodsIdsUsedByOf = trainingOrganization.connections
          .flatMap((connection) => connection.application.methods)
          .map((method) => method.methodId)

        const apiMethodsIdsUsedByOfWithoutDuplicate = uniq(
          apiMethodsIdsUsedByOf,
        )
        await getSyncMethods
          .mutateAsync({
            data: {
              ids: apiMethodsIdsUsedByOfWithoutDuplicate,
            },
          })
          .then((data: SyncMethodsLists) => {
            if (!isEqual(relatedSyncMethods, data)) {
              setRelatedSyncMethods(data)
            }
          })
      }

      fetchSyncMethods()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingOrganization])

  const globalSyncMethods = useMemo(
    () =>
      relatedSyncMethods?.globalSyncMethods?.sort(
        (a, b) => a.index - b.index,
      ) ?? [],
    [relatedSyncMethods],
  )

  const singleSyncMethods = useMemo(
    () =>
      relatedSyncMethods?.singleSyncMethods?.sort(
        (a, b) => a.index - b.index,
      ) ?? [],
    [relatedSyncMethods],
  )

  const values = useMemo(
    () => ({
      sasStatus,
      trainingOrganization,
      globalSyncMethods,
      singleSyncMethods,
      setRelatedSyncMethods,
      isLoading,
      setAutoRefetch,
    }),
    [
      sasStatus,
      trainingOrganization,
      globalSyncMethods,
      singleSyncMethods,
      isLoading,
      setAutoRefetch,
    ],
  )

  return (
    <SyncMethodsContext.Provider value={values}>
      {children}
    </SyncMethodsContext.Provider>
  )
}

const useSyncMethods = () => {
  const context = useContext(SyncMethodsContext)
  if (context === undefined) {
    throw new Error('useSyncMethods must be used within a SyncMethodsProvider')
  }
  return context
}

export { SyncMethodsProvider, useSyncMethods }
