import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import {
  btnCommonsx,
  globalSyncWrapperSx,
  singleSyncWrapperSx,
} from '../../styles'
import SyncStateIcon from '../SyncStateIcon'

function IndependentSynchro({
  syncMethodWithState,
  handleClick,
  index,
  isDisabled,
}) {
  return (
    <Box sx={globalSyncWrapperSx}>
      <IconButton
        size="small"
        sx={btnCommonsx}
        onClick={() => handleClick(index)}
        disabled={isDisabled}
      >
        <AutorenewIcon />
      </IconButton>
      <Box sx={singleSyncWrapperSx}>
        <Typography variant="body1" sx={{ color: 'primary.light' }}>
          {syncMethodWithState.syncMethod.description}
        </Typography>
        <SyncStateIcon
          syncState={syncMethodWithState.syncStateWithName?.state}
        />
      </Box>
    </Box>
  )
}

export default IndependentSynchro
