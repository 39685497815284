import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { AVAILABILITES } from 'domains/of/enums'
import { MethodToSync } from 'domains/sasMethods/types'
import { CircularProgressSizes } from 'enums/circularProgressSizes'
import { find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSyncMethods } from '../../contexts/SyncMethodsContext'
import GlobalSingleSynchro from './components/GlobalSingleSynchro'
import IndependentSynchro from './components/IndependentSynchro'
import { ExtractSyncState } from './helper'
import useStartChainedSynchronizations from './hooks/startChainedSynchronizations'
import useStartSingleSynchronization from './hooks/startSingleSynchronization'
import { btnCommonsx, globalSyncWrapperSx } from './styles'

function ManualSynchro({ id }) {
  const { t } = useTranslation()
  const {
    globalSyncMethods,
    singleSyncMethods,
    sasStatus,
    trainingOrganization,
    isLoading,
    setAutoRefetch,
  } = useSyncMethods()
  const [globalSyncStates, setGlobalSyncStates] = useState<MethodToSync[]>([])
  const [singleSyncStates, setSingleSyncStates] = useState<MethodToSync[]>([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const startSingleSynchronization = useStartSingleSynchronization({
    baseUrl: trainingOrganization?.url,
  })

  const startChainedSynchronizations = useStartChainedSynchronizations({
    id,
    setGlobalSyncStates,
    baseUrl: trainingOrganization?.url,
  })

  useEffect(
    function refreshButtonsAndSyncStates() {
      if (!isEmpty(sasStatus)) {
        setIsButtonDisabled(sasStatus.availability === AVAILABILITES.BUSY.value)
        if (isEmpty(sasStatus.methods)) {
          setSingleSyncStates([])
          setGlobalSyncStates([])
        } else {
          setGlobalSyncStates(ExtractSyncState(sasStatus, true))
          setSingleSyncStates(ExtractSyncState(sasStatus, false))
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sasStatus, singleSyncMethods],
  )

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h3" sx={{ color: 'secondary.main' }}>
        {t('manual-synchro')}
      </Typography>
      {isLoading ? (
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <CircularProgress
            sx={{ color: (theme) => theme.palette.secondary.main }}
            size={CircularProgressSizes.MEDIUM}
          />
        </Box>
      ) : (
        <Box sx={{ mt: 1 }}>
          {singleSyncMethods &&
            singleSyncMethods.map((syncMethod, index) => {
              const syncMethodWithState = {
                syncMethod,
                syncStateWithName: find(
                  singleSyncStates,
                  (m) => m.description === syncMethod.description,
                ),
              }
              return (
                <IndependentSynchro
                  index={index}
                  key={syncMethod.name}
                  syncMethodWithState={syncMethodWithState}
                  handleClick={() => {
                    setIsButtonDisabled(true)
                    setAutoRefetch(true)
                    startSingleSynchronization(index)
                  }}
                  isDisabled={isButtonDisabled}
                />
              )
            })}
          <Box sx={globalSyncWrapperSx}>
            <IconButton
              size="small"
              sx={btnCommonsx}
              onClick={() => {
                setIsButtonDisabled(true)
                setAutoRefetch(true)
                startChainedSynchronizations()
              }}
              disabled={isButtonDisabled}
            >
              <AutorenewIcon />
            </IconButton>
            <Typography variant="body1" sx={{ color: 'primary.light' }}>
              {t('global')}
            </Typography>
          </Box>
          {globalSyncMethods &&
            globalSyncMethods.map((syncMethod) => {
              const syncMethodWithState = {
                syncMethod,
                syncStateWithName: find(
                  globalSyncStates,
                  (m) => m.description === syncMethod.description,
                ),
              }
              return (
                <GlobalSingleSynchro
                  key={syncMethod.name}
                  syncMethodWithState={syncMethodWithState}
                />
              )
            })}
        </Box>
      )}
    </Box>
  )
}

export default ManualSynchro
